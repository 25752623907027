import React from "react";
import MarkdownPage from "./MarkdownPage";

const MARKDOWN = `# Terms of Service for ARK

## Effective Date: November 10th, 2024

Welcome to ARK! By using our mobile application (the “App”), you agree to these Terms of Service (“Terms”). Please read them carefully.

1. Acceptance of Terms

By accessing or using the App, you agree to be bound by these Terms and our Privacy Policy. If you do not agree, please do not use our App.

2. Eligibility

To use the App, you must be at least 18 years old. By using the App, you represent and warrant that you meet this requirement.

3. Account Registration

You may be required to register for an account to access certain features of the App. You agree to:
- Provide accurate, current, and complete information during registration.
- Maintain the security of your account and promptly notify us of any unauthorized use.
- Be responsible for all activities that occur under your account.

4. User Conduct

When using the App, you agree not to:
- Post, upload, or share content that is unlawful, harmful, abusive, or violates the rights of others.
- Use the App for any illegal or unauthorized purposes.
- Attempt to disrupt or interfere with the App's operations.
- Any and all propagation of sexual content such as explicit sexual content, nudity for the purpose of eliciting sexual themes or any implicit sexual content is strictly prohibited and will be met with severe punishment. Implicit sexual content includes any form of “sex-ed” content, content (fitness content, dance content, etcétera) with the purpose of exposing the human body in a sexual manner, and any form of breastfeeding content done with the purpose of exposing the naked body of a woman for erotic purposes.

5. Content Rights

5.1. Our Content

The App and its original content, features, and functionality are owned by Immaculata Technologies LLC and protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

6. Privacy

Your data will only be used for in-house customization of your recommended feed. Immaculata Technologies LLC or ARK will never sell your data to anyone.

7. Termination

We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason, including if you breach these Terms.

8. Disclaimers

The App is provided “as is” and “as available.” Immaculata Technologies LLC makes no warranties, expressed or implied, and disclaims all other warranties including, without limitation, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.

9. Limitation of Liability

In no event shall Immaculata Technologies LLC, its directors, employees, or affiliates, be liable for any damages (including, but not limited to, damages for loss of data, profits, or due to business interruption) arising out of the use or inability to use the App.

10. Governing Law

These Terms shall be governed and construed in accordance with the laws of Florida, United States of America, without regard to its conflict of law provisions.

11. Changes to Terms

We reserve the right to modify these Terms at any time. We will notify you of changes by posting the new Terms on the App. Your continued use of the App after any changes indicates your acceptance of the new Terms.


12. Contact Us

If you have any questions about these Terms, please contact us at [felix@the-ark.blue](mailto:felix@the-ark.blue).`;

const TermsPage = () => {
  return <MarkdownPage markdown={MARKDOWN} />;
};

export default TermsPage;

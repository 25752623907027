import React from "react";
import MarkdownPage from "./MarkdownPage";

const MARKDOWN = `# Privacy Policy for ARK
## Effective Date: November 10, 2024

Welcome to ARK! At Immaculata Technologies LLC, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our mobile application (the “App”).

1. Information We Collect

When you use the App, we may collect the following types of information:
- Personal Information: Information you provide during account registration, such as your name, email address, and other details.
- Usage Data: Information about your interactions with the App, including preferences, activity logs, and other analytics.
- Device Information: Details about the device you use to access the App, such as operating system, browser type, and unique device identifiers.

2. How We Use Your Information

We use your information to:
- Provide, maintain, and improve the App's functionality.
- Customize and personalize your recommended feed based on your preferences.
- Ensure compliance with legal obligations and prevent unauthorized or unlawful activities.

3. Data Sharing and Disclosure
Your data is kept confidential and is used only for in-house customization and App
improvements. ***We do not sell your data to third parties***.

We may share your data in the following circumstances:
- To comply with legal requirements, such as court orders or regulatory obligations.
- With trusted service providers who assist in maintaining and improving the App, under strict confidentiality agreements.

4. Data Security
 
We implement industry-standard security measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.

5. Your Rights

As a user, you have the right to:
- Access, update, or delete your personal information.
- Withdraw consent for data processing at any time (note that this may limit App functionality).
- Contact us to address privacy-related concerns at [felix@the-ark.blue](mailto:felix@the-ark.blue).

6. Children's Privacy

The App is intended for users aged 18 and older. We do not knowingly collect personal information from individuals under 18. If you believe we have inadvertently collected such information, please contact us immediately.

7. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. Any changes will be posted within the App, and the updated policy will include a new effective date. Continued use of the App after changes constitutes acceptance of the updated policy.

### Contact Us

If you have questions or concerns about this Privacy Policy, please contact us at: 

Email: [felix@the-ark.blue](mailto:felix@the-ark.blue)

This Privacy Policy complements your Terms of Service and governs your use of the App regarding data protection. Please ensure you read both documents to fully understand your rights and responsibilities.`;

const PrivacyPage = () => {
  return <MarkdownPage markdown={MARKDOWN} />;
};

export default PrivacyPage;

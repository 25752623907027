import React from "react";
import { Box } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

interface MarkdownPageProps {
  markdown: string;
}

const MarkdownPage = ({ markdown }: MarkdownPageProps) => {
  return (
    <Box py={8} px={4}>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <Box as="h1" fontSize="2xl" fontWeight="bold" mb={6} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <Box
              as="h2"
              fontSize="xl"
              fontWeight="semibold"
              mb={3}
              mt={6}
              {...props}
            />
          ),
          h3: ({ node, ...props }) => (
            <Box as="h3" fontWeight="semibold" mb={4} {...props} />
          ),
          p: ({ node, ...props }) => (
            <Box as="p" mb={4} color="gray.400" lineHeight="tall" {...props} />
          ),
          a: ({ node, ...props }) => (
            <Box as="a" mb={4} color="blue.600" lineHeight="tall" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <Box
              as="ol"
              mb={4}
              lineHeight="tall"
              fontWeight="semibold"
              {...props}
            />
          ),
          ul: ({ node, ...props }) => (
            <Box
              as="ul"
              ml={4}
              mb={4}
              color="gray.400"
              lineHeight="tall"
              {...props}
            />
          ),
        }}
      >
        {markdown}
      </ReactMarkdown>
    </Box>
  );
};

export default MarkdownPage;
